<template>
    <div id="mobile-tip" v-show="isMobile">
        <div class="new-version">
            <b class="mobile-version-tip-text">{{language['Text13']}}</b>
            <p class="mobile-tip-text">{{language['Text11']}}</p>
            <img src="../assets/mobile3.webp" class="mobile-tip-img">
            <div class="mobile-tip-button">{{language['Text12']}}</div>
        </div>
        <div class="old-version">
            <b class="mobile-version-tip-text">{{language['Text14']}}</b>
            <p class="mobile-tip-text">{{language['Text15']}}</p>
            <img src="../assets/mobile1.png" class="mobile-tip-img"> 
            <p class="mobile-tip-text">{{language['Text16']}}</p>
            <img src="../assets/mobile2.png" class="mobile-tip-img"> 
        </div>
    </div>
    <div id="content" v-show="!isMobile">
        <div class="content-box content-box1">
            <video class="content-box1-video"   playsinline="" autoplay muted loop="" ref="contentBox1Video">
                <source src="../assets/media/首页背景.mp4" type="video/mp4">
            </video>
            <div class="content-box1-mask" ref="contentBox1Mask">
                <img src="../assets/编组.svg" class="icon"/>
                <img src="../assets/TCL LINK ICON.png" class="logo"/>
                <span class="content-box1-mask-title">{{language['Text1']}}</span>
                <span class="content-box1-mask-text1">{{language['Text2']}}</span>
                <!-- <span class="content-box1-mask-text2">{{language['Text3']}}</span> -->
                <div class="content-box1-mask-text2">
                    <div>
                    {{language['Text17']}}
                    </div>
                    <div>
                    {{language['Text3']}}
                    </div>
                </div>
                <div class="download-btn" style="margin-top: 80px;"><img src="../assets/window-icon.svg" />{{language['Text4']}}</div>
            </div>
        </div>
        <div class="content-box content-box2">
            <div class="content-box2-area1">
                <video class="content-box2-video"   playsinline="" autoplay muted loop="">
                    <source src="../assets/media/01.mp4" type="video/mp4">
                </video>
            </div>
            <div class="content-box2-area2">
                <span class="content-box2-area2-title">{{language['Text5']}}</span>
                <span class="content-box2-area2-text">{{language['Text6']}}</span>
            </div>
        </div>
        <div class="content-box content-box3">
            <div class="content-box3-area1">
                <span class="content-box3-area1-title">{{language['Text7']}}</span>
                <span class="content-box3-area1-text">{{language['Text8']}}</span>
            </div>
            <div class="content-box3-area2">
                <video class="content-box3-video"  playsinline="" autoplay muted loop="">
                    <source src="../assets/media/02.mp4" type="video/mp4">
                </video>
            </div>
        </div>
        <div class="content-box content-box4">
            <div class="content-box4-area1">
                <video class="content-box4-video"   playsinline="" autoplay muted loop="">
                    <source src="../assets/media/03.mp4" type="video/mp4">
                </video>
            </div>
            <div class="content-box4-area2">
                <span class="content-box4-area2-title">{{language['Text9']}}</span>
                <span class="content-box4-area2-text">{{language['Text10']}}</span>
            </div>
        </div>
        <div class="footer hidden" ref="footer">
            <div style="display: flex;">
                <img src="../assets/TCL LINK ICON.png" class="footer-logo"/>
                <span class="footer-text">{{language['Text1']}}</span>
            </div>
            <div class="download-btn"><img src="../assets/window-icon.svg" />{{language['Text4']}}</div>
        </div>
    </div>
</template>
<script>
import * as Languages from '../languages';
export default {
    name:'MainPage',
    data(){
        return {
            isMobile:false,
            language:["ru","ru-RU"].indexOf(navigator.language)>-1?Languages['ru']:Languages['en']
        }
    },
    created(){
        const that = this;
        window.onscroll = function() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const clientHeight = document.body.clientHeight;
            const scrollHeight = document.body.scrollHeight;
            let show = scrollHeight-clientHeight-scrollTop>20?'footer hidden':'footer'
            that.$refs.footer.setAttribute('class',show);
        };
        window.onresize = function(){
            that.resizeMask();
            that.isMobileDevice();
        }
        this.isMobileDevice();
    },
    mounted(){
        const that = this;
        this.$refs.contentBox1Video.addEventListener("play", function () {
            that.resizeMask();
        });
        this.$refs.contentBox1Video.addEventListener("loadmetadata", function () {
            that.resizeMask();
        });
        const downloadBtns = document.querySelectorAll(".download-btn");
        downloadBtns.forEach((o)=>{
            o.addEventListener("click",function () {
                location.href = "https://teye-multiscreen.s3.eu-west-1.amazonaws.com/TCLLINKSetup.exe";
            });
        })
        const jumpBtn = document.querySelector(".mobile-tip-button");
        jumpBtn.addEventListener("click",function(){
            window.location.href = 'tclapps://scanner';
            setTimeout(function(){
                let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden 
                if(typeof hidden =="undefined" || hidden ==false){
                    //跳转到应用下载地址
                    // window.location.href ="https://a.app.qq.com/o/simple.jsp?pkgname=com.lucky.luckyclient";
                    // alert('尚未安装此应用')
                }
            }, 3000);
        })
    },
    methods:{
        isMobileDevice(){
            this.isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent);
        },
        resizeMask(){
            this.$refs.contentBox1Mask.setAttribute('style',`height:${this.$refs.contentBox1Video.clientHeight}px`);
        }
    }
}
</script>
<style scoped>
  
    .icon{
        position:absolute;
        top:30px;
        left:30px;
    }
    .logo{
        width: 80px;
        margin-bottom: 20px;
    }
    #mobile-tip,#weixin-tip{
        width: calc(100% - 60px);
        margin:30px 30px;
        
    }
    #weixin-tip{
        text-align: center;
    }
    .mobile-tip-button{
        width:100%;
        text-align: center;
        color:white;
        background: #4C72BF;;
        border-radius: 20px;
        padding: 15px 0px;
        margin:30px auto;
    }
    .mobile-tip-img{
        width: 100%;
    }
    .mobile-tip-text{
        font-family: Roboto-Regular;
        font-size: 16px;
        color: #000000;
        font-weight: 400;
    }
    /* #content{
        width:100%;
        position: relative;
    } */
    .content-box1{
        width:100%;
    }
    .content-box1-mask{
        background: rgba(0,0,0,.6);
        width:100%;
        position: absolute;
        top:0px;
        left:0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .content-box1-mask-title{
        color:white;
        font-family: Arial-BoldMT;
        font-size: 48px;
        font-weight: 700;
    }
    .content-box1-mask-text1{
        font-family: Arial-BoldMT;
        font-size: 26px;
        color: #FFFFFF;
        margin-top: 10px;
        font-weight: 700;
    }
    .content-box1-mask-text2{
        font-family: ArialMT;
        font-size: 20px;
        color: #E0E0E0;
        margin-top: 60px;
        font-weight: 400;
        max-width: 90%;
        text-align: center;
    }
    .download-btn {
        color: white;
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        width: 232px;
        height: 42px;
        background-color: #4c72bf;
        border-radius: 2px;
        padding:0 4px;
    }
    .download-btn img{
        margin-right: 5px;
    }
    .content-box1-video{
        width: 100%;
        z-index:-9999;
    }
    .content-box{
        background: black;
    }
    .content-box2,.content-box3,.content-box4{
        padding:10% 0;
        width:100%;
        /* height:700px; */
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        /* border-bottom: 1px solid #eee; */
    }
    .content-box4{
        padding: 10% 0 20% 0;
    }
    .content-box2-area1,.content-box3-area2,.content-box4-area1{
        width: 48%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content-box2-area2,.content-box3-area1,.content-box4-area2{
        width:30%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: center;
    }
    .content-box2-area2-title,.content-box3-area1-title,.content-box4-area2-title{
        font-family: Arial-BoldMT;
        font-size: 40px;
        /* color: #000000; */
        color:white;
    }
    .content-box2-area2-text,.content-box3-area1-text,.content-box4-area2-text{
        font-family: ArialMT;
        font-size: 20px;
        /* color: #363636; */
        color:#fafafa;
        margin-top: 30px;
    }
    .content-box2-video,.content-box3-video,.content-box4-video{
        width:100%;
        border-radius: 5px;
    }
    .footer{
        padding: 0 8%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 84%;
        position: fixed;
        height:100px;
        background: #000000;
        bottom:0;
        transition: bottom .5s;
    }
    .footer.hidden{
        /* display: none; */
        bottom:-120px
    }
    .footer-logo{
        width:40px;
        display: inline-block;
    }
    .footer-text{
        font-size: 24px;
        color:white;
        margin-left: 20px;
        line-height: 40px;
    }
    .footer-download-btn{
        color: white;
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        width: 232px;
        height: 42px;
        background-color: #4c72bf;
        border-radius: 2px;
    }
</style>